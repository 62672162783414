import React from "react";
import PropTypes from "prop-types";
import withProps from "recompose/withProps";
import compose from "recompose/compose";
import classNames from "classnames";
import withFormHandlers from "../withFormHandlers";
import BaseInput from "../BaseInput";
import IconButton from "theme/components/atoms/Button/IconButton";
import { defineMessages, injectIntl } from "react-intl";

const messages = defineMessages({
  invalidInteger: {
    id: "components.atoms.Form.Input.NumberInput.invalidInteger",
    defaultMessage: "This field must be a number",
  },
  decrement: {
    id: "components.atoms.Form.Input.NumberInput.decrement",
    defaultMessage: "Decrease",
  },
  increment: {
    id: "components.atoms.Form.Input.NumberInput.increment",
    defaultMessage: "Increase",
  },
});

const [DECREASE, INCREASE] = [-1, 1];

const NumberInput = (props) => {
  const {
    getErrors,
    isPristine,
    isValid,
    isDirty,
    appearance,
    baseInputProps,
    autoComplete,
    autocomplete,
    onChange,
    id,
    name,
    value,
    intl,
    disabled,
    min,
    max,
    increment = 1,
    ...rest
  } = props;

  const classes = classNames({
    "input--invalid": getErrors().length,
    "input--valid": isValid(),
    [`input--${props.inputSize}`]: props.inputSize,
  });

  const errors = getErrors();

  const calculateNextNumber = (operationFactor) => {
    if (value % increment === 0) {
      return value + operationFactor * increment;
    }

    /* If we increase quantity */
    if (operationFactor === INCREASE) {
      return value + operationFactor * (increment - (value % increment));
    }

    // If we reduce quantity
    return value + operationFactor * (value % increment);
  };

  return (
    <BaseInput
      input={
        <div
          className={classNames("number-input", {
            [`number-input--${appearance}`]: appearance,
          })}
        >
          {appearance !== "small" ? (
            <div className="number-input__button">
              <IconButton
                icon="minus"
                title={intl.formatMessage(messages.decrement)}
                onClick={() => onChange(calculateNextNumber(DECREASE))}
                onDisableClick={() => {}}
                state={
                  value <= min || disabled || value <= increment
                    ? "disabled"
                    : undefined
                }
                type="button"
              >
                -
              </IconButton>
            </div>
          ) : null}
          <div className="number-input__input">
            <input
              className={classes}
              name={name}
              id={id}
              type="text"
              inputMode="number"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              disabled={disabled}
              {...rest}
            />
          </div>
          {appearance !== "small" ? (
            <div className="number-input__button">
              <IconButton
                icon="plus"
                title={intl.formatMessage(messages.increment)}
                onClick={() => onChange(calculateNextNumber(INCREASE))}
                onDisableClick={() => {}}
                state={value >= max || disabled ? "disabled" : undefined}
                type="button"
              >
                +
              </IconButton>
            </div>
          ) : null}
        </div>
      }
      help={props.help}
      errors={errors}
      {...props.baseInputProps}
    />
  );
};

NumberInput.propTypes = {
  appearance: PropTypes.oneOf(["default", "small"]),
  min: PropTypes.number,
  max: PropTypes.number,
};

export default compose(
  injectIntl,
  withProps((props) => ({
    validations: {
      isInt: true,
      ...(typeof props.validations === "string"
        ? { [props.validations]: true }
        : props.validations || {}),
    },
    validationErrors: {
      isInt: props.intl.formatMessage(messages.invalidInteger),
      ...(props.validationErrors || {}),
    },
  })),
  withFormHandlers({
    getValueFromEvent: (value) => {
      if (value.length === 0) {
        return null;
      } else {
        let parsedValue = parseFloat(value);
        if (isNaN(parsedValue)) {
          parsedValue = null;
        } else if (parsedValue < 0) {
          parsedValue = -parsedValue;
        }
        return parsedValue;
      }
    },
    getValueFromProps: ({ value }) => value,
    getPropsFromValue: (value) => {
      if (value === null || value === "") {
        value = "";
      } else {
        const parsedValue = Number(value);
        if (!Number.isNaN(parsedValue) && parsedValue < 0) {
          value = -parsedValue;
        }
      }

      return { value: value };
    },
  })
)(NumberInput);
