module.exports = {
  analytics: {
    enable: false,
    debug: process.env.NODE_ENV !== "production",
    defaultSettings: {},
    integrations: [
      {
        name: "google-analytics",
        needConsent: true,
        enabledByDefault: true,
        settings: (authorization) => {
          return {
            "Google Analytics": {
              trackingId: "UA-123-1",
              anonymizeIp: !authorization,
            },
          };
        },
      },
    ],
  },
};
