import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import deprecateRenamedProperty from "web/core/dx/deprecateRenamedProperty";

const FormActions = ({ children, type, appearance }) => {
  if (!appearance) {
    appearance = deprecateRenamedProperty(
      "FormActions",
      "type",
      "appearance",
      type
    );
  }

  children = (Array.isArray(children) ? children : [children]).filter((e) =>
    Boolean(e)
  );

  return (
    <div
      className={classNames("form-actions", {
        [`form-actions--${appearance}`]: appearance,
        "form-actions--only-one": children.length <= 1,
      })}
    >
      {children.map((child, index) => {
        return (
          <div
            className="form-actions__element"
            key={child.key ?? `key-${index}`}
          >
            {child}
          </div>
        );
      })}
    </div>
  );
};

FormActions.propTypes = {
  appearance: PropTypes.oneOf([
    "default",
    "vertical",
    "center",
    "full",
    "start",
    "start-small",
    "end",
    "end-small",
  ]),
  children: PropTypes.node.isRequired,
};

export default FormActions;
