import React from "react";
import { defineMessages, useIntl } from "react-intl";
import Icon from "theme/components/atoms/Icon";

const messages = defineMessages({
  notificationMessage: {
    id: "components.molecules.IconWithNotification.notificationMessage",
    defaultMessage: "{quantity} new notifications",
  },
});

const IconWithNotification = ({
  notification,
  notificationMessage,
  ...props
}) => {
  const intl = useIntl();
  return (
    <span className="icon-with-notification">
      <Icon {...props} />
      {notification && notification > 0 ? (
        <>
          <span
            aria-hidden="true"
            className="icon-with-notification__notification"
          >
            {notification}
          </span>
          <span className="sr-only">
            {notificationMessage ||
              intl.formatMessage(messages.notificationMessage, {
                quantity: notification,
              })}
          </span>
        </>
      ) : null}
    </span>
  );
};

export default IconWithNotification;
